import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

function SingleProject({ project, num, parent }) {
  return (
    <div className="project sm:overflow-hidden relative">
      <Link
        to={num === 4 && parent === 'front' ? '/projekt' : `/projekt/${project.slug.current}`}
        className="block h-full mb-8 sm:mb-0"
      >
        <GatsbyImage
          image={project.featuredImage?.asset.gatsbyImageData}
          alt={`Projekt: ${project.name}`}
          className="project-img sm:transform duration-300 h-full"
        />
        <div className="bg-gray-100 p-4 sm:p-6 project-desc transform duration-300 sm:translate-y-full sm:absolute bottom-0 w-full">
          <h5>{project.name}</h5>
          <p className="mb-0 text-gray-500">{project.caption}</p>
        </div>
      </Link>
      {num === 4 && parent === 'front' && (
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline-block px-6 py-4 leading-none text-white bg-greenblue border-2 border-greenblue rounded transition duration-500 ease-in-out">
          Se alla projekt
        </span>
      )}
    </div>
  );
}

export default function ProjectList({ projects, className, parent }) {
  return (
    <div className={className}>
      {projects.map((project, index) => (
        <SingleProject key={project._id} project={project} num={index + 1} parent={parent} />
      ))}
    </div>
  );
}
