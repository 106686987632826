import React from 'react';
import { graphql } from 'gatsby';
import Head from '../../components/head';
import Layout from '../../components/layout';
import ProjectList from '../../components/projectList';

export default function AllProjects({ data: { allProjects } }) {
  const projects = allProjects.nodes;

  return (
    <Layout>
      <Head
        title="Våra projekt"
        description="Varje poolbygge är unikt och utgår alltid från kundens behov och specifika pooldrömmar. Här presenteras några
          av våra referensprojekt."
      />
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page mb-8 sm:mb-20">
        <h1>
          <span aria-hidden="true" />
          Våra projekt
        </h1>
        <p className="sm:text-lg">
          Varje poolbygge är unikt och utgår alltid från kundens behov och specifika pooldrömmar. Här presenteras några
          av våra referensprojekt.
        </p>
      </div>
      <ProjectList
        projects={projects}
        className="projects max-w-7xl m-auto gap-4 block sm:grid grid-cols-2 sm:grid-cols-5"
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    allProjects: allSanityProject(sort: { fields: orderRank, order: ASC }) {
      nodes {
        slug {
          current
        }
        _id
        name
        caption
        featuredImage {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
